import React, { useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '@components/common/layout'
import StoryContent from '@components/scrollytelling/whatsapp-lebanon/story-content'
import FooterSection from '@components/scrollytelling/whatsapp-lebanon/footer-section'

import { StorylineContextProvider } from '@store/whatsapp-lebanon/storyline-context'

import '@styles/whatsapp-lebanon.scss'

const defaultLanguage = 'en-GB'

const WhatsappLebanonStory = (props) => {
	const Images = props.data.allFile.edges

	const getImageByName = useCallback(
		(name) => {
			return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
		},
		[Images]
	)

	return (
		<>
			<StorylineContextProvider>
				<Helmet
					htmlAttributes={{
						lang: 'en',
					}}
				>
					<	meta name='viewport' content='width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no' />
				</Helmet>
				<Layout
					bgColor={'bg-white'}
					shareProps={{
						title: 'The New Humanitarian | WhatsApp, Lebanon?',
						url: 'https://www.thenewhumanitarian.org/2022/07/28/whatsapp-lebanon-timeline',
						socialTitle: 'The New Humanitarian | WhatsApp, Lebanon?',
						socialDescription: "How 5 people lived through one of the world's worst-ever economic crises, as told through their WhatsApp messages.",
						socialImage: 'https://assets.thenewhumanitarian.org/s3fs-public/styles/responsive_large/public/2022-11/General-Scene.jpg.webp?itok=WtiJQc6h',
						nodeId: 262488,
						updatedTime: '2022-07-28T10:10:59+01:00',
						modiefiedTime: '2022-07-28T10:10:59+01:00',
						publishedTime: '2022-07-28T10:10:59+01:00',
					}}
				>
					<section id={'horizontal-timeline'} className={`mt-24`}>
						<StoryContent defaultLanguage={defaultLanguage} getImage={getImageByName} />
					</section>
					<FooterSection />
				</Layout>
			</StorylineContextProvider>
		</>
	)
}

export default WhatsappLebanonStory

export const query = graphql`
	query WhatsappLebanonImagesEN {
		allFile(
			filter: { sourceInstanceName: { eq: "images" }, extension: { in: ["jpg", "png", "jpeg"] }, relativePath: { glob: "stories/2022/whatsapp-lebanon/*" } }
		) {
			edges {
				node {
					extension
					dir
					modifiedTime
					name
					relativePath
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED,
							quality: 85,
						)
					}
				}
			}
		}
	}
`
